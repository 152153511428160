<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        v-if="validFedId || validFedId === null"
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <LoginForm v-if="validFedId || validFedId === null" />
      <div
        v-else
        class="mx-auto"
      >
        <b-alert
          variant="danger"
          show
          class="p-2 mx-auto mt-4"
        >
          Your authentication link is invalid or has expired.
        </b-alert>
      </div>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { BRow, BCol, BImg } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import LoginForm from './LoginForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    LoginForm,
  },
  mixins: [togglePasswordVisibility],
  props: {

    validFedId: { type: Boolean, default: null },
  },
  data() {
    return {
      sideImg: '',
      imgUrl: '',
    }
  },
  computed: {

  },
  async created() {
    this.sideImg = await this.$generateDownloadUrl('media/login.svg')
    if (this.$store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = await this.$generateDownloadUrl('media/login-v2-dark.svg')
    }
    this.imgUrl = this.sideImg
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
